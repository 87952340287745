@import '../common/variables.less';
@import 'src/styles/typography.less';

@navWidth: 270px;

main.container {
  display: flex;
  margin-top: @mainSpace * 2 !important;

  & > .content {
    flex: 1;
    padding-right: @mainSpace * 4;
  }

  & > .info-nav {
    width: @navWidth;
  }
}

.to-noobs .title,
.tag-cloud .title {
  position: relative;
  display: block;
  margin-bottom: @mainSpace * 2;
  padding-bottom: @mainSpace / 2;
  border-bottom: 1px solid @borderColor;
}
.to-noobs .title { margin-right: @mainSpace * -4; }
.to-noobs .sentences {
  // padding-left: @font-size + 2px;
  li { margin-bottom: 0; }
}

.tag-cloud > .tags {
  margin-top: -5px;
  font-size: @font-size-small;
  
  & > .tag {
    display: inline-block;
    margin: 5px @mainSpace 0 0;
    line-height: @font-size + 4px;

    &.xl { font-size: @font-size + 4px; }
    &.l { font-size: @font-size + 2px; }
    &.m { font-size: @font-size; }
  }
}

.topics {
  margin-top: @mainSpace * 6;
  
  & > figcaption {
    margin-bottom: @mainSpace * 2;
    padding-bottom: @mainSpace / 2;
    border-bottom: 1px solid @borderColor;
  }

  & .list {
    position: relative;
    left: -3px;
    padding-left: 0;
  }

  & .list-item {
    @size: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    // height: @size;
    // line-height: @size;
    
    & > .list-style {
      display: inline-block;
      margin-right: 5px;
      width: @size;
      text-align: center;
      user-select: none;
    }

    & > a {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      white-space: nowrap;
    }
  }
}

.articles {
  // position: relative;
  margin-top: @mainSpace * 4;
  // min-height: @mostUseLength * 2;
  padding: 40px 40px (40px + @textHeight) 0;
  // box-shadow: 0 2px 8px rgba(0,0,0,.09);

  .list {
    padding-left: 0;
    list-style-type: none;
  }

  .list-item {
    padding: 30px 0;
    border-bottom: 1px dotted @borderColor;

    &:first-of-type { padding-top: 0 }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // position: absolute;
    // left: 0;
    // bottom: 40px;
    margin-top: -0.25rem;
    height: 20px;
    font-size: @font-size-small;

    & > #more {
      position: relative;
      left: -1rem;
      color: @mainColor;
      font-size: @font-size-small;
    }
  }
}

// 骨架屏
.articles .skeleton {
  .tc-list-item-article {
    position: absolute;
    right: 100%;
    width: 820px;
  }

  .skeleton-article {
    .title {
      margin-bottom: 8px;
      height: 28px;
      width: @mostUseLength;
      background-color: @skeletonColor;
      border-radius: @radius;
    }
    .description {
      line-height: 0;

      & span {
        display: inline-block;
        margin-right: 4px;
        height: 12px;
        width: 48px;
        background-color: @skeletonColor;
        border-radius: @radius;
      }
    }
    .p {
      margin-top: 4px;
      height: 18px;
      background-color: @skeletonColor;
      border-radius: @radius;
    }
  }
}

.topics .list.skeleton {
  left: 0;
  overflow: hidden;

  .list-item:not(.skeleton-topic) {
    position: absolute;
    right: -100%;
  }

  .skeleton-topic {
    height: 20px;
    background-color: @skeletonColor;
    border-radius: @radius;
  }
}

.tags.skeleton {
  position: relative;
  overflow: hidden;
  margin-right: -4px;

  .tag {
    position: absolute;
    right: -100%;
  }

  @width: (@mostUseLength - 4px * 3) / 4;
  .skeleton-tag {
    display: inline-block;
    margin-right: 4px;
    width: @width;
    height: 20px;
    background-color: @skeletonColor;
    border-radius: @radius;

    &:nth-of-type(1) {
      width: 90px;
    }
    &:nth-of-type(3) {
      width: (@mostUseLength - @width) - 90px - 4px * 2;
    }
    &:nth-last-of-type(2) {
      width: @width * 1.75;
    }
  }
}
